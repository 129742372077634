@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

// pitchIN styleguides based on Figma
// reference: https://github.com/tailwindlabs/tailwindcss/issues/6979
*, ::before, ::after {
    border-width: 0;
    border-style: solid;
    border-color: currentColor /* or gray-DEFAULT / gray-400 */;
}

.list--reset {
    ul {
        @apply tw-pl-5
    }
}

.list--checkmark {
    @apply tw-list-none;

    li {
        @apply tw-flex tw-gap-2.5;
        &:before {
            content: "";
            @apply tw-inline-block tw-w-5 tw-h-5 tw-bg-contain tw-bg-no-repeat tw-opacity-50 tw-flex-shrink-0;
            background-image: url("/assets/img/icons/svgs/circle-check.svg");
        }
    }
}

.list--dot {
    @apply tw-flex tw-flex-wrap tw-list-none;

    > li {
        &::after {
            content: "";
            @apply tw-w-[2px] tw-h-[2px] tw-inline-block tw-rounded-full tw-bg-body tw-opacity-50 tw-align-middle tw-mx-2;
        }

        &:last-child::after {
            @apply tw-hidden;
        }
    }
}

.p-text-emphasis {
    @apply tw-font-heading tw-text-body-dark tw-leading-normal tw-text-lg tw-font-normal;
}

.p-text-h1 {
    @apply tw-font-bold tw-text-4xl sm:tw-text-5xl sm:tw-leading-[1.15]; 
}

.p-text-h2 {
    @apply tw-font-bold tw-text-4xl tw-leading-heading;
}

.p-text-h3 {
    @apply tw-font-bold tw-text-2xl tw-leading-heading;
}

.p-text-h4 {
    @apply tw-font-bold tw-text-xl tw-leading-heading;
}

.p-text-headline {
    @apply tw-text-4xl sm:tw-text-[2.7rem] tw-font-bold tw-leading-[1.3];
}

.p-text-subtitle {
    @apply tw-text-2xl;

    &--light {
        @apply tw-text-body tw-text-opacity-[65%];
    }

    &--sm {
        @apply tw-text-lg;
    }   
}

.p-text-preheader {
    @apply tw-font-bold tw-leading-normal tw-uppercase tw-tracking-widest;
}

.p-text-sub1, .p-text-sub2 {
    @apply tw-text-[1.5625rem] tw-font-normal tw-leading-normal;
}

.p-text-sub2 {
    @apply tw-text-body tw-text-opacity-[65%];
}

.p-text-p {
    @apply tw-font-body tw-text-base tw-leading-normal;
}

.p-text-body {
    @apply tw-font-body tw-text-base tw-text-[#444444] tw-leading-normal;
}

.p-text-tag {
    @apply tw-text-current tw-text-[0.625rem] tw-tracking-widest tw-text-opacity-75 tw-uppercase;

    &--bg {
        @apply tw-px-2 tw-py-1 tw-bg-gray-100 tw-rounded;
    }

    &--round {
        @apply tw-text-xs tw-text-body tw-text-opacity-50 tw-px-2.5 tw-py-1 tw-font-semibold tw-rounded-full tw-inline-block tw-leading-none tw-normal-case tw-whitespace-nowrap tw-bg-divider tw-bg-opacity-30;
    }

    &--dot {
        &::before {
            content: "";
            @apply tw-w-[5px] tw-h-[5px] tw-rounded-full tw-inline-block tw-mr-1 tw-bg-current tw-opacity-70 tw-relative -tw-top-[2px];
        }
    }
}

.p-tag {
    &--error {
        @apply tw-text-primary tw-bg-primary tw-bg-opacity-5;
    }

    &--success {
        @apply tw-text-secondary-light tw-bg-secondary-lightest tw-bg-opacity-20;
    }

    &--info {
        @apply tw-text-info tw-bg-info-lightest tw-bg-opacity-20;
    }

    &--warning {
        @apply tw-text-warning tw-bg-warning-light tw-bg-opacity-40;
    }

    &--purple {
        @apply tw-text-[#3538CD] tw-bg-[#3538CD] tw-bg-opacity-5;
    }

    &--pink {
        @apply tw-text-[#C11574] tw-bg-[#C11574] tw-bg-opacity-5;
    }

    &--orange {
        @apply tw-text-[#E0623F] tw-bg-[#E0623F] tw-bg-opacity-5;
    }
}

.p-text-label {
    @apply tw-text-body tw-text-sm tw-leading-normal tw-text-opacity-75;
}

.p-badge {
    @apply tw-inline-block tw-text-xs tw-font-bold tw-uppercase tw-tracking-widest tw-px-3 tw-py-1 tw-bg-opacity-10 tw-rounded-full;

    &--icon {
        @apply tw-relative tw-pl-8;

        .badge-icon {
            @apply tw-absolute tw-w-4 tw-h-4 tw-left-3 tw-top-[4px];
        }
    }

    &--hoverable {
        @apply hover:tw-bg-opacity-70 hover:tw-text-white tw-cursor-pointer;
    }
}

.p-text-sidebar-header {
    @apply tw-text-primary tw-font-bold tw-text-base tw-uppercase tw-text-left;
}

.p-text-title-separater {
    @apply tw-text-body-dark tw-font-bold tw-text-opacity-70 tw-text-2xl tw-uppercase tw-flex tw-items-center tw-gap-1.5;

    &:after {
        content: "";
        @apply tw-border-b tw-border-black tw-border-opacity-20 tw-inline-block tw-flex-1;
    }
}

.p-accordion-header {
    @apply tw-py-2.5 tw-text-base tw-px-5 tw-w-full tw-flex tw-items-center;
}

.p-icon-tooltip {
    @apply tw-w-5 tw-h-5 tw-opacity-30 hover:tw-opacity-80 #{!important};
}

.p-text-tooltip {
    @apply tw-border-b tw-border-dashed tw-border-body-dark hover:tw-border-opacity-60;
}

.p-link {
    @apply tw-text-primary hover:tw-text-[#740000] tw-cursor-pointer;

    &-underline {
        @apply tw-underline hover:tw-underline tw-px-0 tw-bg-transparent;
    }
    
    &-primary {
        @apply tw-cursor-pointer tw-bg-transparent tw-text-black hover:tw-text-black hover:tw-text-opacity-80;
    }

    &-secondary {
        @apply tw-cursor-pointer tw-bg-transparent tw-text-link hover:tw-text-link hover:tw-text-opacity-80;
    }
}

.p-help-icon {
    &, &--sm, &--lg {
        @apply tw-opacity-50 hover:tw-opacity-80;
    }

    &--sm {
        @apply tw-h-3 tw-w-3 #{!important};
    }

    & {
        @apply tw-h-4 tw-w-4 #{!important};
    }

    &--lg {
        @apply tw-h-5 tw-w-5 #{!important};
    }
}

.p-table {
    @apply tw-text-sm;

    &__wrapper {
        @apply tw-border tw-border-table tw-rounded-10px tw-shadow-sm;
    }

    &__body {
        @apply tw-overflow-x-auto;

        &-label {
            @apply tw-font-semibold tw-mb-0;
        }
    }

    &__cell {
        @apply tw-flex-1;
    }

    &__footer {
        @apply tw-py-2.5 tw-px-4;
    }

    &__pagination {
        @apply tw-float-none;

        &-icon {
            @apply tw-w-auto tw-h-auto tw-mx-auto #{!important};
        }

        &-label {
            @apply tw-hidden md:tw-inline-block;
        }

        .pagination {
            @apply tw-mb-0;
        }

        .page-item {
            &:first-child, &:last-child {
                .page-link {
                    @apply tw-border-divider tw-w-auto tw-px-2.5 tw-font-medium tw-text-body tw-shadow-sm tw-transition hover:tw-bg-divider hover:tw-bg-opacity-30;
                }
            }

            &:first-child {
                @apply tw-mr-auto;
            }

            &:last-child {
                @apply tw-ml-auto;
            }
            
            &.disabled {
                .page-link {
                    @apply tw-text-body tw-text-opacity-30 tw-bg-divider tw-bg-opacity-10;
                }
            }
        }
    }

    &.mat-mdc-table {
        @apply tw-bg-transparent;
    }

    .mat, .mat-mdc {
        &-row, &-header-row, &-footer-row {
            @apply tw-border-table;
        }

        &-header-row {
            @apply tw-bg-transparent tw-border-none;
        }

        &-row, &-mdc-row {
            &:first-of-type {
                .mat-cell, .mat-mdc-cell {
                    @apply tw-border-table;
                }
            }

            &:hover {
                .mat-cell, .mat-mdc-cell {
                    @apply tw-transition-colors lg:tw-bg-divider lg:tw-bg-opacity-30;
                }
            }
        }

        &-header-cell {
            @apply tw-text-body tw-opacity-90 tw-text-xs tw-normal-case tw-font-medium tw-py-3 tw-overflow-hidden tw-bg-transparent;
        }

        &-cell {
            @apply tw-py-4 tw-text-black tw-border-t tw-border-table;
        }

        &-header-cell, &-cell, &-footer-cell {
            @apply tw-px-3;

            &:first-of-type {
                @apply tw-pl-4;
            }

            &:last-of-type {    
                @apply tw-pr-4;
            }
        }
    }
}

.p-tab {
    @apply tw-list-none tw-flex tw-p-2 tw-bg-divider tw-bg-opacity-40 tw-rounded-lg tw-mb-0 tw-gap-1 tw-overflow-auto lg:tw-overflow-visible;

    &__item {
        @apply tw-text-body tw-rounded-lg tw-cursor-pointer tw-border tw-border-transparent hover:tw-bg-gray-200 hover:tw-bg-opacity-50;

        &.active {
            @apply tw-bg-primary tw-bg-opacity-10 tw-text-primary tw-shadow tw-border-primary tw-border-opacity-15;
            text-shadow: 0px 0px 1px rgb(191,32,38);
        }
    }

    &__link {
        @apply tw-inline-block tw-py-1 tw-px-4 tw-text-inherit lg:tw-min-w-[140px] md:tw-text-center hover:tw-text-inherit;
    }
}

.is-loading {
    .p-skeleton {
        &-bar,
        &-image {
            @apply tw-bg-gray-200 tw-rounded tw-animate-pulse tw-text-transparent #{!important};
        }

        &-bar {
            @apply tw-inline-block tw-min-w-[50px] tw-min-h-[1rem] tw-max-w-full tw-whitespace-nowrap tw-bg-none tw-leading-none;
        }

        &-image {
            @apply tw-shadow-none tw-overflow-visible;

            img {
                @apply tw-hidden;
            }
        }

        &-hidden {
            @apply tw-invisible;
        }

        &-pagination {
            .page-link {
                @apply tw-bg-gray-200 tw-rounded tw-animate-pulse tw-text-transparent tw-border-transparent #{!important};
            }
        }
    }
}

.mat-mdc-progress-bar[role="progressbar"] {
    --mdc-linear-progress-track-height: 10px;
    --mdc-linear-progress-active-indicator-height: 10px;
    overflow: hidden;
}

.notification-wrapper {
    @apply tw-bg-[#f1f1f1] tw-text-black tw-h-0 tw-z-[15] tw-overflow-hidden tw-text-center tw-transition-all tw-duration-500 tw-ease-in-out tw-delay-500 tw-will-change-[height] tw-max-w-full;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;

    &::before, &::after {
        content: "";
        @apply tw-h-2.5 tw-block;
    }

    h1, h2, h3, h4, h5, p {
        @apply tw-text-base tw-mb-0;
    }

    a {
        @apply tw-underline tw-text-inherit hover:tw-text-inherit hover:tw-underline;
    }
}

.tourguide-cta {
    --shift-distance: 0.25rem;
    padding-right: 2.5rem;
    line-height: 2rem;
    z-index: 1;
    border-radius: 2em;
    position: relative;
    transition: color .15s ease-in-out,transform .3s ease,-webkit-transform .3s ease;
    transform: translateX(0);
    color: black !important;
    display: inline-block;
    font-weight: bold;
    white-space: nowrap;

    &::before {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        bottom: 0;
        right: 0;
        left: calc(100% - 2rem);
        border-radius: 1rem;
        height: 2rem;
        z-index: -1;
        background-image: url("/assets/img/icons/svgs/map.svg");
        background-repeat: no-repeat;
        background-position-x: calc(100% - 0.4375rem);
        background-position-y: calc(100% - 0.4375rem);
        background-size: 1.125rem;
        background-color: #ffe01b;
        transform: translateY(-50%);
    }

    &:hover {
        transform: translateX(var(--shift-distance));
        color: black !important;

        &::before {
            background-position-x: calc(100% - var(--shift-distance)*3.5);
            left: calc(var(--shift-distance) * -4);
            transition: left .3s cubic-bezier(.4,1.7,.7,.8),right .3s ease,background-position .3s ease;
        }
    }
}

.overview-title {
    @apply tw-text-base tw-font-bold tw-uppercase;
}

.overview-label {
    @apply tw-text-body tw-text-sm tw-leading-normal tw-text-opacity-75 tw-block tw-mb-0;
}

.overview-value {
    @apply tw-text-black tw-font-semibold;
}

.dot--glowing {
    @apply tw-inline-block tw-w-1.5 tw-h-1.5 tw-rounded-full tw-align-middle tw-animate-glowing;
}

// Grid table for reports
.grid-table__head {
    @apply tw-text-xs tw-text-body tw-text-opacity-70 tw-border-b tw-border-divider;
}

.grid-table__head > div,
.grid-table__body > div {
    @apply tw-py-1.5 tw-px-4 tw-self-center;
}